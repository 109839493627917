import { Button } from '@finn/design-system';
import { ModalIcons } from '@finn/ui-components';
import { makeStyles } from '@material-ui/core';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  frame: {
    padding: theme.spacing(6, 3),
  },
  icon: {
    margin: theme.spacing(0.5, 0.5, 4),
  },
  description: {
    margin: theme.spacing(2, 0, 4),
  },
  buttonWrapper: {
    maxWidth: theme.spacing(25),
    margin: '0 auto',
  },
}));
type Props = {
  localeId: string;
  onClose(): void;
};

const NotifySuccess: React.FC<Props> = ({ localeId, onClose }) => {
  const classes = useStyles();
  const i18n = useIntl();

  const successTitle = i18n.formatMessage({ id: `${localeId}.successTitle` });
  const successDescription = i18n.formatMessage({
    id: `${localeId}.successDescription`,
  });
  const successCtaLabel = i18n.formatMessage({
    id: `${localeId}.successCtaLabel`,
  });

  return (
    <div className={classes.frame}>
      <ModalIcons.Success className={classes.icon} />
      <h1 className="mobile-t1-semibold md:web-t1-semibold">{successTitle}</h1>
      <p className="body-16-light mb-8 mt-4">{successDescription}</p>
      <div className={classes.buttonWrapper}>
        <Button className="w-full" type="button" onClick={onClose}>
          {successCtaLabel}
        </Button>
      </div>
    </div>
  );
};

export default memo(NotifySuccess);
