import { LegalText } from '@finn/auto-ui/modules/legal/constants';
import { getLegalAllText } from '@finn/auto-ui/modules/legal/utils';
import { Button } from '@finn/design-system';
import { parseToHtml } from '@finn/ui-utils';
import { useState } from 'react';
import { useIntl } from 'react-intl';

const GDPRDetails = () => {
  const [showGdprDetails, setShowGdprDetails] = useState(false);
  const i18n = useIntl();

  const gdprDetails = getLegalAllText(
    i18n.locale,
    LegalText.JOBAUTO_WHITEPAPER_LEAD_FORM_GPDR_DETAILS
  );
  const handleGDPRToggle = (e) => {
    e.preventDefault();
    setShowGdprDetails(!showGdprDetails);
  };

  return (
    <>
      <Button variant="action" size="md" onClick={handleGDPRToggle}>
        {i18n.formatMessage({
          id: `general.${
            showGdprDetails ? 'lessInformation' : 'moreInformation'
          }`,
        })}
      </Button>
      {showGdprDetails && (
        <p className="body-16-regular">
          <br />
          {parseToHtml(gdprDetails)}
        </p>
      )}
    </>
  );
};

export default GDPRDetails;
