import axios from 'axios';
import * as Yup from 'yup';

import config from '~/config';

export type FormInputs = {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  company: string | null;
  gdprConsent: boolean | null;
};

export const initialValues: FormInputs = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  company: '',
  gdprConsent: null,
};

export const createValidationSchema = () =>
  Yup.object().shape<FormInputs>({
    firstName: Yup.string().nullable().required('yup.required'),
    lastName: Yup.string().nullable().required('yup.required'),
    email: Yup.string().email('yup.email').nullable().required('yup.required'),
    phone: Yup.string().nullable(),
    company: Yup.string().nullable(),
    gdprConsent: Yup.boolean().oneOf([true]).required('yup.required'),
  });

export const validationSchema = createValidationSchema();

export const integromatSubmit = async (values: FormInputs) => {
  const { data, status } = await axios.get(
    config.INTEGROMAT_JOBAUTO_WHITEPAPER_FORM,
    {
      params: values,
    }
  );

  return { data, status };
};
