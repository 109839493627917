import { ModalContainer, ModalKey } from '@finn/ua-modals';
import { getLocaleFromContext } from '@finn/ui-utils';
import { GetStaticProps, NextPage } from 'next';
import React from 'react';

import { BasePageData } from '~/types/cosmicModules';
import PageBodyBuilder from '~/ui-modules/PageBodyBuilder';
import { addSentryRenderPageBreadcrumb } from '~/utils/sentry';

import JobAutoWhitepaperModal from '../../../components/JobAuto/JobAutoWhitepaperModal';

interface IProps {
  pageData: BasePageData;
  slug: string;
}

const JobAuto: NextPage<IProps> = ({ pageData, slug }: IProps) => {
  if (!pageData?.metadata?.modules) return null;

  addSentryRenderPageBreadcrumb(`/pages/b2b/job-auto`, slug.toUpperCase());

  return (
    <PageBodyBuilder data={pageData}>
      <ModalContainer
        modalKey={ModalKey.JOBAUTO_WHITEPAPER_MODAL}
        ModalComponent={JobAutoWhitepaperModal}
      />
    </PageBodyBuilder>
  );
};

export const getStaticProps: GetStaticProps = async (ctx) => {
  const { getCosmicObject } = await import('~/services/server/cosmic');
  const slug = 'b2b-job-auto';
  const locale = getLocaleFromContext(ctx);
  const pageData = await getCosmicObject({
    slug: `page-${slug}`,
    locale,
  });

  return {
    props: {
      pageData,
      slug,
    },
    revalidate: 300,
  };
};

export default JobAuto;
