import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paperRoot: {
    padding: theme.spacing(9, 6, 6),
    textAlign: 'center',
    maxWidth: theme.spacing(68.5),
  },
  space: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(4),
  },
  absolute: {
    position: 'absolute',
  },
  relative: {
    position: 'relative',
  },
  checkbox: {
    marginTop: -theme.spacing(1),
  },
  details: {
    marginLeft: theme.spacing(3),
  },
}));
